@use "./../VCX/base";

.ex_base {
  flex-direction: column;
}

.ex_header {
  width: 100%;
  height: auto;
  padding-left: 0.5em;
  font-weight: bold;
  flex: none;
  flex-direction: row;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  background-color: var(--ColorMap-AccentBaseColorBck);
  color: var(--ColorMap-AccentBaseColorFrg);
}

.ex_other_header_lite {
  background-color: var(--ColorMap-AccentBaseColorFrg);
  color: var(--ColorMap-AccentBaseColorBck);
  border-style: solid;
  border-width: calc(var(--Zoom) * 1px);
  border-color: var(--ColorMap-ContentFrame1);
}

.ex_header_lite {
  background-color: transparent;
  color: var(--ColorMap-ContentDecorateColorFrg);
  border-bottom-style: solid;
  border-bottom-width: calc(var(--ExpanderControl-LiteSeparationWidth) * 1px);
  border-bottom-color: var(--ColorMap-AccentBaseColorBck);
}

.ex_header_view {
  background-color: var(--ColorMap-BaseColorBck1);
  color: var(--ColorMap-AccentBaseColorFrg);
}

.ex_header_menu {
  background-color: var(--ColorMap-BaseColorFrg1);
  color: var(--ColorMap-BaseColorBck1);
  border-style: solid;
  border-width: calc(var(--Zoom) * 1px);
  border-color: var(--ColorMap-ContentFrame1);
}

.ex_header_font {
  @include base.expander_header_font;
}

.ex_content {
  border: calc(1px * var(--Zoom)) solid var(--ColorMap-ContentFrame1);
  border-top-width: 0px;
  background: transparent;
  flex: 1 0 auto;

  padding: calc(var(--MarginY) * 2px) calc(var(--MarginX) * 2px);
  @media only screen and (max-width: 980px) {
    padding: "0 !important";
  }
}

.ex_content_edit {
  border-color: var(--ColorMap-ContentFrame1ForChange);
}

.ex_content_preview {
  background-color: var(--ColorMap-DataBrowseColorBck) !important;
}

.ex_header_link {
  display: block;
}

.ex_header_link:hover {
  text-decoration: underline;
  cursor: pointer;
}
