@import "../utils";

.tc_list_detail {
  flex: 1 1 auto;

  &.tc_right {
    flex-direction: row-reverse;
    .tc_list_detail_tabs {
      border-left: getZoom(6) solid var(--ColorMap-AccentBaseColorBck);
    }
  }
  &.tc_left {
    .tc_list_detail_tabs {
      border-right: getZoom(6) solid var(--ColorMap-AccentBaseColorBck);
    }
  }

  &.tc_left,
  &.tc_right {
    .tc_list_detail_tabs {
      @media (max-width: 980px) {
        border: none;
        height: getZoom(40);
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  @media (max-width: 980px) {
    flex-direction: column;
  }

  .tc_list_detail_tabs {
    flex: 0 0 auto;
    background-color: var(--ColorMap-BaseColorBck1);

    p {
      text-align: center;
    }

    .img {
      margin: 0 auto;
    }

    .tc_list_detail_button {
      width: getZoom(60);
      height: getZoom(60);
      justify-content: center;
      color: var(--ColorMap-BaseColorFrg1);
      border-bottom: 1px solid var(--ColorMap-BaseColorFrg1);
      padding: getZoom(3);
      flex: 0 0 auto;

      &.tc_icon_center {
        width: getZoom(50);
        height: getZoom(50);
      }
      &.tc_icon_left_right {
        width: getZoom(100);
        height: getZoom(40);
        justify-content: normal;
        .tc_button_content {
          width: 100%;
        }
        .img {
          flex: 0 0 auto !important;
        }
        p {
          flex: 1 1 100%;
        }
      }

      p {
        white-space: normal;
      }

      &.tc_current_page {
        background-color: var(--ColorMap-AccentBaseColorBck);
      }

      @media (max-width: 980px) {
        display: none;

        &.tc_current_page {
          display: flex;
          height: auto;
          width: auto;
          justify-content: flex-start;
          flex: 1 1 auto;
          border: none;
          color: var(--ColorMap-BaseColorBck1);
          background-color: var(--ColorMap-BaseColorFrg1);
        }
      }
    }
  }
}
